<template>
  <div class="info-five">
    <van-cell-group>
      <template #title>
        <div style="text-align: left;">税务身份</div>
      </template>
      <van-cell>
        <div class="theme-color" v-html="message"></div>
      </van-cell>
    </van-cell-group>

    <van-cell-group style="margin-top: 20px;">
      <van-field
        v-model="form.is_usa"
        label="是否美国公民或美国税务居民"
        label-width="16em"
        @click="shwoUsaSheet = true"
        placeholder="请选择"
        input-align="right"
        maxlength="100"
        right-icon="arrow"
        readonly
      />
      <div v-if="form.is_usa === '否'">
        <van-field
          label-width="11em"
          v-model="form.reside_addr"
          label="居留司法管辖国家/地区"
          maxlength="100"
          clearable
          input-align="right"
          placeholder="请输入"
        />
        <van-field
          v-model="form.has_tax_no"
          label-width="18em"
          @click="shwoTexSheet = true"
          input-align="right"
          placeholder="请选择"
          maxlength="100"
          right-icon="arrow"
          readonly
        >
          <template #left-icon>
            <div
              class="label van-field__label"
              style="color: gray;width: 160px;"
              @click.stop="showTexTip"
            >
              是否有税务编号
              <van-icon style="display: inline; position: relative; top: 3px;" name="info-o" />
            </div>
          </template>
        </van-field>
        <van-field
          v-if="form.has_tax_no === '是'"
          label-width="7em"
          v-model="form.tax_no"
          input-align="right"
          label="税务编号"
          clearable
          maxlength="100"
          placeholder="请输入"
        />
        <van-field
          v-if="form.has_tax_no === '否 原因B'"
          label-width="7em"
          v-model="form.no_tax_no_reason"
          input-align="right"
          clearable
          label="原因"
          maxlength="100"
          placeholder="请输入"
        />
      </div>
    </van-cell-group>
    <van-action-sheet
      v-model:show="shwoTexSheet"
      :actions="actionsTex"
      @select="onTexSheetSelect"
      cancel-text="取消"
      close-on-click-action
    />
    <van-action-sheet
      v-model:show="shwoUsaSheet"
      :actions="actionsUsa"
      @select="onUsaSheetSelect"
      cancel-text="取消"
      close-on-click-action
    />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
        下一步(风险认知)
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, Dialog, Icon, CellGroup, Cell, Field, ActionSheet } from "vant";
import { useStore } from "vuex";
import { onMounted, reactive, watch, ref } from "vue";
import httpApi from "../../utils/httpApi";

const message = `根据《税务条例》有关交换财务账户资料的法律条文，请提供以下资料，列明（a）账户持有人的居留司法管辖区，亦即账户持有人的税务管辖区（香港包括在内）及（b）该居留司法管辖区发给账户持有人的税务编号。列出所有居留司法管辖区。如没有提供税务编号，必须选择填写实际原因：
原因 A – 账户持有人的居留司法管辖区并没有向其居民发出税务编号。
原因 B – 账户持有人不能取得税务编号，并填写账户持有人不能取得税务号的具体原因。
原因 C – 账户持有人无须提供税务编号。居留司法管辖区的主管机关不需要账户持有人披露税务编号。
`.replace(/\r?\n/g, "<br />");
const alertMsg = `目前网上开户仅限非美国公民或非美国税务居民的个人用户使用，请先联系客服获取邀请。
美国公民或美国税务居民，需联系客服获取开户方式。
客服电话：（+852）27280838`;
const texMsg =
  "如账户持有人是中国大陆/香港税务居民，税务编号是其中国大陆/香港身份证号码，不同国家/地区的税务识别号码规范及详情，请参阅国际经合组织官方网站。";
let submitForm;
export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [ActionSheet.name]: ActionSheet,
    [Icon.name]: Icon
  },
  data() {
    return {
      message,
      shwoTexSheet: false,
      shwoUsaSheet: false,
      actionsTex: [
        { name: "是" },
        { name: "否 原因A" },
        { name: "否 原因B" },
        { name: "否 原因C" }
      ],
      actionsUsa: [{ name: "是" }, { name: "否" }]
    };
  },
  methods: {
    showTexTip() {
      Dialog.confirm({
        title: "税务编号说明",
        messageAlign: "left",
        message: texMsg,
        cancelButtonText: "关闭",
        confirmButtonText: "查看网站",
        confirmButtonColor: "rgba(0,0,0,0.6)"
      })
        .then(() => {
          const href =
            "http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/";
          if (this.$store.state.user.platform === "APP") {
            this.$util.callApp(`outsidewebview?url=${href}`);
          } else {
            window.open(href, "_blank");
          }
        })
        .catch(() => {});
    },
    onTexSheetSelect(value) {
      this.shwoTexSheet = false;
      this.form.has_tax_no = value.name;
    },
    onUsaSheetSelect(value) {
      this.shwoUsaSheet = false;
      this.form.is_usa = value.name;
    },
    async next() {
      if (this.disabledNext.value) return;
      if (this.form.is_usa === "是") {
        Dialog.alert({
          title: "说明",
          messageAlign: "left",
          confirmButtonText: "我知道了",
          message: alertMsg,
          confirmButtonColor: "rgba(0,0,0,0.6)"
        });
        return;
      }
      const { code, msg } = await this.$api.postInfoStepFive(submitForm);
      if (code === 200) {
        this.$router.push({ path: "/assess/index" });
      } else {
        this.$toast(msg);
      }
    },
    before() {
      this.$router.go(-1);
    }
  },
  setup() {
    const form = reactive({
      is_usa: "",
      reside_addr: "",
      has_tax_no: "",
      tax_no: "",
      no_tax_no_reason: ""
    });
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "资料填写");
      store.commit("setStepsValue", ["填写资料(5/5)", "风险认知", "提交资料"]);
      store.commit("setStepsActive", 0);
      const result = await httpApi.getInfoStepFive();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach(item => {
        if (result.data[item]) {
          form[item] = result.data[item];
        }
      });
    });

    const disabledNext = ref(true);
    watch(form, val => {
      submitForm = { ...val };

      if (submitForm.is_usa !== "否") {
        delete submitForm.reside_addr;
        delete submitForm.has_tax_no;
      }

      if (submitForm.has_tax_no !== "是") {
        delete submitForm.tax_no;
      }
      if (submitForm.has_tax_no !== "否 原因B") {
        delete submitForm.no_tax_no_reason;
      }

      disabledNext.value = false;
      if (submitForm.is_usa === "是") {
        disabledNext.value = false;
      }
      Object.getOwnPropertyNames(submitForm).forEach(item => {
        if (!submitForm[item].trim()) {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(submitForm, disabledNext);
    });
    return {
      form,
      disabledNext
    };
  }
};
</script>

<style lang="less">
.info-five {
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
